import React from "react";
import ModalWrapperWithoutHeight from "../ModalWrapperWithoutHeight/ModalWrapperWithoutHeight";
import s from "./ModalBlockResult.module.css";

import CvOriginal from "../../assets/images/resultPageImages/cv_original.jpg";
import Day1 from "../../assets/images/resultPageImages/day-1.jpg";
import Day2 from "../../assets/images/resultPageImages/day-2.jpg";
import Day3 from "../../assets/images/resultPageImages/day-3.jpg";
import Day4 from "../../assets/images/resultPageImages/day-4.jpg";

import { ReactComponent as WinnerCup } from "../../assets/icons/cup.svg";

const ModalBlockResult = ({ onClose, currentTaskId, blockTasksArr }) => {
  let day;
  const question = blockTasksArr.indexOf(currentTaskId);
  if (question <= 10) {
    day = 1;
  } else if (10 < question && question <= 15) {
    day = 2;
  } else if (15 < question && question <= 32) {
    day = 3;
  } else if (32 < question && question <= 44) {
    day = 4;
  } else {
    day = 5;
  }

  return (
    <ModalWrapperWithoutHeight onClose={onClose}>
      <div className={s.content}>
        <article className={s.container}>
          <h2 className={s.modalTitle}>Юхууу ты молодец, сделано:</h2>
          <header className={s.header}>
            <section className={s.headerRigthSide}>
              <WinnerCup className={s.winnerCup} width="118" />
              <h2 className={s.headerTitle}>Достижение</h2>
              {day === 1 && (
                <>
                  <p>
                    Поздравляем! Ты сделал html разметку правой части резюме.
                  </p>
                </>
              )}
              {day === 2 && (
                <>
                  <p>
                    Поздравляем! Ты сделал html разметку левой части резюме.
                  </p>
                </>
              )}
              {day === 3 && (
                <p>
                  Поздравляем! Ты добавил css правила (к уже имеющейся html
                  разметке) для правой части резюме.
                </p>
              )}
              {day === 4 && (
                <p>
                  Поздравляем! Ты добавил css правила (к уже имеющейся html
                  разметке) для левой части резюме.
                </p>
              )}
            </section>
            <section className={s.headerLeftSide}>
              <h2 className={s.headerTitle}>Макет</h2>
              {day === 1 && (
                <p>Цель - сделать html разметку правой части резюме</p>
              )}
              {day === 2 && (
                <p>Цель - сделать html разметку левой части резюме</p>
              )}
              {day === 3 && (
                <p>
                  Цель - добавить css правила (к уже имеющейся html разметке)
                  для правой части резюме
                </p>
              )}
              {day === 4 && (
                <p>
                  Цель - добавить css правила (к уже имеющейся html разметке)
                  для левой части резюме
                </p>
              )}
            </section>
          </header>

          <div className={s.imageContainer}>
            {day === 1 && (
              <img className={s.imgcv} src={Day1} alt="result today" />
            )}
            {day === 2 && (
              <img className={s.imgcv} src={Day2} alt="result today" />
            )}
            {day === 3 && (
              <img className={s.imgcv} src={Day3} alt="result today" />
            )}
            {day === 4 && (
              <img className={s.imgcv} src={Day4} alt="result today" />
            )}

            <img className={s.imgcv} src={CvOriginal} alt="cv" />
          </div>
          <button onClick={onClose} className={s.button}>
            Продолжить
          </button>
        </article>
      </div>
    </ModalWrapperWithoutHeight>
  );
};

export default ModalBlockResult;
