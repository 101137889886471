import React, { Component } from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import BigBrowserHeader from "../BigBrowserHeader/BigBrowserHeader";
import s from "./BrowserResultModal.module.css";

class BrowserResultModal extends Component {
  render() {
    const { onClose, openModalRefreshQuestion } = this.props;
    return (
      <ModalWrapper onClose={onClose}>
        <div className={s.wrapper}>
          <BigBrowserHeader
            openModalRefreshQuestion={openModalRefreshQuestion}
            onClose={onClose}
          />
          {/* тут должен быть код для отображения в консоле */}
          <p>Код для отображения в консоле</p>
        </div>
      </ModalWrapper>
    );
  }
}

export default BrowserResultModal;
