import React from "react";
import s from "./MobileAndTabletStub.module.css";
import logo from "../../assets/images/logo.png";

const MobileAndTabletStub = () => {
  return (
    <div className={s.container}>
      <img src={logo} alt="logo" />
      <p className={s.text}>Розшир браузер або зайди з десктопа.</p>
    </div>
  );
};

export default MobileAndTabletStub;
