import React from "react";
// import logo from "../../assets/images/logo.png";
import styles from "./HeaderResultPage.module.css";
import { ReactComponent as Logo } from "../../assets/icons/logo/goit_logo_small.svg";

const HeaderResultPage = ({ day }) => {
  return (
    <header className={styles.header_container}>
      <div className={styles.headerContent}>
        <h1 className={styles.logo_container}>
          <a href="https://goit.ua/" target="_blank" rel="noopener noreferrer">
            {/* <img src={logo} alt="logo" /> */}
            <Logo width="90" height="30" />
          </a>
          <span className={styles.logo_title}>Знакомство с HTML и CSS</span>
        </h1>

        <p className={styles.days_container}>
          Марафон:&ensp;<span>день {day}</span>&ensp;из 5
        </p>
      </div>
    </header>
  );
};

export default HeaderResultPage;
