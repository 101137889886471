import React, { Component } from "react";
import s from "./ResultPage.module.css";
import HeaderResultPage from "../../components/HeaderResultPage/HeaderResultPage";
import ErrorInvalidTokenOrBlock from "../../components/ErrorInvalidTokenOrBlock/ErrorInvalidTokenOrBlock";
import FooterResultPage from "../../components/FooterResultPage/FooterResultPage";

import CvOriginal from "../../assets/images/resultPageImages/cv_original.jpg";
import Day1 from "../../assets/images/resultPageImages/day-1.jpg";
import Day2 from "../../assets/images/resultPageImages/day-2.jpg";
import Day3 from "../../assets/images/resultPageImages/day-3.jpg";
import Day4 from "../../assets/images/resultPageImages/day-4.jpg";

class ResultPage extends Component {
  state = {
    day: null,
  };

  componentDidMount() {
    const { location } = this.props;
    const day = new URLSearchParams(location.search).get("day");
    this.setState({ day: Number(day) });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      const { location } = this.props;
      const day = new URLSearchParams(location.search).get("day");
      this.setState({ day: day });
    }
  }

  render() {
    let { day } = this.state;
    day = Math.floor(day);
    if (isNaN(day) || day >= 5) {
      return <ErrorInvalidTokenOrBlock />;
    }

    return (
      <>
        <HeaderResultPage day={day} />
        <article className={s.container}>
          <header className={s.header}>
            <section className={s.headerRigthSide}>
              <h2 className={s.headerTitle}>Достижение дня</h2>
              {day === 1 && (
                <>
                  <p>
                    Сегодня в 1-й день марафона ты сделал html разметку правой
                    части резюме
                  </p>
                  <p>
                    * css к правой части мы добавим в 3-м дне марафона (чтобы
                    были такие же отступы, цвета, шрифты как на макете)
                  </p>
                </>
              )}
              {day === 2 && (
                <>
                  <p>
                    Сегодня во 2-й день марафона ты сделал html разметку левой
                    части резюме
                  </p>
                  <p>
                    * css к левой части мы добавим в 4-м дне марафона (чтобы
                    были такие же отступы, цвета, шрифты как на макете)
                  </p>
                </>
              )}
              {day === 3 && (
                <p>
                  Сегодня в 3-й день марафона ты добавил css правила (к уже
                  имеющейся html разметке) для правой части резюме
                </p>
              )}
              {day === 4 && (
                <p>
                  Сегодня в 4-й день марафона ты добавил css правила (к уже
                  имеющейся html разметке) для левой части резюме
                </p>
              )}
            </section>
            <section className={s.headerLeftSide}>
              <h2 className={s.headerTitle}>Макет</h2>
              {day === 1 && (
                <p>
                  Цель на сегодня - сделать html разметку правой части резюме
                </p>
              )}
              {day === 2 && (
                <p>
                  Цель на сегодня - сделать html разметку левой части резюме
                </p>
              )}
              {day === 3 && (
                <p>
                  Цель на сегодня - добавить css правила (к уже имеющейся html
                  разметке) для правой части резюме
                </p>
              )}
              {day === 4 && (
                <p>
                  Цель на сегодня - добавить css правила (к уже имеющейся html
                  разметке) для левой части резюме
                </p>
              )}
            </section>
          </header>

          <div className={s.imageContainer}>
            {day === 1 && (
              <img className={s.imgcv} src={Day1} alt="result today" />
            )}
            {day === 2 && (
              <img className={s.imgcv} src={Day2} alt="result today" />
            )}
            {day === 3 && (
              <img className={s.imgcv} src={Day3} alt="result today" />
            )}
            {day === 4 && (
              <img className={s.imgcv} src={Day4} alt="result today" />
            )}

            <img className={s.imgcv} src={CvOriginal} alt="cv" />
          </div>
        </article>
        {day === 4 && (
          <p className={s.text}>
            Завтра тебе предстоит ознакомиться с очень захватывающей темой -
            анимации. Ты создашь анимированную кнопку для скачивания своего
            резюме. До завтра!
          </p>
        )}
        {day !== 4 && <p className={s.text}>До завтра!</p>}
        <FooterResultPage />
      </>
    );
  }
}

export default ResultPage;
