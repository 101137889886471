import React from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import s from "./CongratsModal.module.css";
import { ReactComponent as Close } from "../../assets/icons/winnerCup/close.svg";
import { ReactComponent as WinnerCup } from "../../assets/icons/cup.svg";
import utilsFunctionBlockDefinition from "../../utils/blockDefinition";

const CongratsModal = ({
  onClose,
  blockTasksArr,
  block,
  // getTotalProgress,
}) => {
  const tasksCount = blockTasksArr.length;

  const blockIndex = utilsFunctionBlockDefinition(block);

  return (
    <ModalWrapper
      onClose={() => {
        onClose();
        // getTotalProgress();
      }}
      hideCloseButton
    >
      <section className={s.wrapper}>
        {/* <img className={s.winnerCup} src={cup} alt="award" /> */}
        <WinnerCup className={s.winnerCup} width="118" />
        <h2 className={s.modalTitle}>ВІДМІННА РОБОТА!</h2>
        <Close
          className={s.close}
          onClick={() => {
            onClose();
            // getTotalProgress();
          }}
        />
        <div className={s.contentWrapper}>
          <p className={s.text}>
            Ти успішно пройшов {blockIndex}-й блок завдань!
          </p>
          <p className={s.text}>Твои результаты:</p>
          <p className={s.result}>
            <span className={s.countTasks}>{tasksCount}</span>
            правильних відповідей
          </p>
        </div>
      </section>
    </ModalWrapper>
  );
};

export default CongratsModal;
