import React from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { ReactComponent as Close } from "../../assets/icons/winnerCup/close.svg";
import s from "./ModalPolitics.module.css";

const ModalPolitics = ({ onClose }) => {
  return (
    <ModalWrapper onClose={onClose}>
      <div className={s.content}>
        <Close onClick={onClose} className={s.close} />
        <h2 className={s.modalMainTitle}>ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ</h2>
        <p className={s.text}>
          Погодившись брати участь в марафоні ви надаєте згоду на обробку
          персональних даних, погоджуєтеся з запропонованими нами каналами
          комунікації, з можливістю отримання розсилок та комерційних
          пропозицій. Беручи дану політику ви також підтверджуєте, що
          ознайомилися з переліком своїх прав як суб'єкта даних.
        </p>

        <h3 className={s.title}>1. ЗБІР ОСОБИСТОЇ ІНФОРМАЦІЇ </h3>

        <p className={s.text}>
          1.1. Під час заповнення контактних форм при реєстрації ми просимо вас
          повідомити необхідну особисту інформацію в обсязі, який є необхідним
          для надання послуг.
        </p>

        <p className={s.text}>
          1.2. Файли cookie - коли ви переходите по посиланнях, ми відправляємо
          один або кілька файлів cookie на ваш комп'ютер або інший пристрій.
          Файли cookie використовуються для того, щоб підвищувати якість послуг,
          що надаються: зберігати налаштування користувача.
        </p>

        <p className={s.text}>
          1.3. Інформація про відвідування - при доступі на сайт наші сервери
          автоматично можуть записувати певну інформацію. Ці журнали сервера
          можуть містити таку інформацію, як веб-запит, IP-адреса, тип і мова
          браузера, дату і час запиту.
        </p>

        <h3 className={s.title}>2. ЗАХИСТ ІНФОРМАЦІЇ</h3>

        <p className={s.text}>
          2.1. Ми вживаємо всіх необхідних заходів для захисту даних від
          несанкціонованого доступу, зміни, розкриття чи знищення. До цих
          заходів відносяться, зокрема, внутрішня перевірка процесів збору,
          зберігання і обробки даних і заходів безпеки, включаючи відповідне
          шифрування і заходи щодо забезпечення фізичної безпеки даних для
          запобігання несанкціонованого доступу.
        </p>

        <h3 className={s.title}>3. ЗМІНА ЦІЄЇ ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ</h3>
        <p className={s.text}>
          3.1. Дана політика конфіденційності може час від часу змінюватися.
          Зміни, що вносяться до політики конфіденційності будуть опубліковані в
          цьому документі.
        </p>
      </div>
    </ModalWrapper>
  );
};

export default ModalPolitics;
