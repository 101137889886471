import React from "react";
import s from "./ErrorServerStub.module.css";
import logo from "../../assets/images/logo.png";

const ErrorStub = () => {
  return (
    <div className={s.container}>
      <img src={logo} alt="logo" />
      <p className={s.text}>
        Технічні роботи на сервері, спробуйте знову пізніше.
      </p>
    </div>
  );
};

export default ErrorStub;
