import MainPage from "../pages/MainPage/MainPage";
import ResultPage from "../pages/ResultPage/ResultPage";

export default {
  MAIN_PAGE: {
    path: "/",
    component: MainPage,
  },
  RESULT_PAGE: {
    path: "/result",
    component: ResultPage,
  },
};
