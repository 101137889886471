import React, { Component } from "react";
import styles from "./TaskButtons.module.css";
import { ReactComponent as Refresh } from "../../assets/icons/autorenew-black-18dp.svg";
import { ReactComponent as Movie } from "../../assets/icons/movie-black-18dp.svg";
import Timer from "../Timer/Timer";
import VideoModal from "../VideoModal/VideoModal";

class TaskButtons extends Component {
  state = {
    showPreviewModal: false,
    showPreviewModalFirst: false,
    showVideoModal: false,
  };

  showVideoModal = () => {
    const { showTimer, checkUserSeenHelpVideoForTask } = this.props;
    if (!showTimer) {
      this.setState({ showVideoModal: true });
      checkUserSeenHelpVideoForTask();
    }
  };
  closeVideoModal = () => this.setState({ showVideoModal: false });

  render() {
    const {
      checkTest,
      timerEndTime,
      metadata,
      showTimer,
      hideTimer,
      clientWidth,
      // isTheoryAndTaskShow,
      currentTaskId,
      blockTasksArr,
      // getTotalProgress,
      openModalRefreshQuestion,
      openCongratsModal,
      taskSuccessfullyPassed,
      getNextTask,

      // showResultArea,
      // hideResultArea,
      isShowResultArea,
    } = this.props;

    const {
      // showPreviewModal,
      // showPreviewModalFirst,
      showVideoModal,
    } = this.state;

    const isLastQuestion =
      blockTasksArr.indexOf(currentTaskId) === blockTasksArr.length - 1;

    return (
      <div
        style={{ margin: isShowResultArea ? "5px 0" : "0" }}
        className={styles.buttonsContainer}
      >
        {!taskSuccessfullyPassed && (
          <button className={styles.checkButton} onClick={checkTest}>
            Перевірити
          </button>
        )}

        {taskSuccessfullyPassed && !isLastQuestion && (
          <button className={styles.checkButton} onClick={getNextTask}>
            Наступне питання
          </button>
        )}

        {taskSuccessfullyPassed && isLastQuestion && (
          <button className={styles.checkButton} onClick={openCongratsModal}>
            Завершити
          </button>
        )}

        <button
          onClick={openModalRefreshQuestion}
          className={styles.buttonWithSvg}
        >
          <Refresh className={styles.svg} />
          Скинути
        </button>

        {/* <button
          onClick={isShowResultArea ? hideResultArea : showResultArea}
          className={styles.buttonWithoutSvg}
        >
          {isShowResultArea
            ? "Приховати секцію результатів"
            : "Показати секцію результатів"}
        </button> */}

        {(clientWidth > 1300 || !showTimer) && (
          <button
            onClick={this.showVideoModal}
            className={
              !showTimer ? styles.buttonWithSvg : styles.disableButtonWithSvg
            }
          >
            <Movie className={!showTimer ? styles.svg : styles.disableSvg} />
            Відеопідказка
          </button>
        )}

        {showTimer && (
          <Timer timerEndTime={timerEndTime} hideTimer={hideTimer} />
        )}

        {showVideoModal && (
          <VideoModal metadata={metadata} onClose={this.closeVideoModal} />
        )}
      </div>
    );
  }
}

export default TaskButtons;
